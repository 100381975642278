<template>
  <div class="page bg-white" id="reorganize">
    <el-row v-if="dataList.length" :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-show="hasPermissionButton(item.hasPermission)"
              v-for="(item,index) in dataList" :key="index">
        <div @click="gotoPage(item)" class="itemdar">
          <el-image style="width: 100%;height: 180px" :src="item.imgUrl" :fit="'fill'"></el-image>
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
            <div class="omit">{{ item.name }}</div>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <div class="noDataBox" v-else>
      <img src="@/assets/img/noData.png" class="noData">
      <div>暂无数据概览</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataOverview",
  data() {
    return {
      dataList: [
        {
          imgUrl: require('@/assets/img/bulletinBoard1.png'),
          name: '藏品数据综合看板',
          pathRess: '/collBoard',
          hasPermission: 'workbench:dataOverview:coll',
        },
        {
          imgUrl: require('@/assets/img/bulletinBoard2.png'),
          name: '雷锋纪念馆数字藏品总览',
          pathRess: '/collectionOverview',
          hasPermission: 'workbench:dataOverview:coll',
        },
      ],
    }
  },

  methods: {
    gotoPage(row) {
      this.$router.push({path: row.pathRess,})
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.itemdar {
  cursor: pointer;
  margin-bottom: 10px;
}

.noDataBox {
  height: calc(100% - 80px);
  padding-top: 5%;

  .noData {
    width: 20%;
  }

  width: 100%;
  text-align: center;
}
</style>